let Nodo = class
{
    constructor(data)
    {
        this.data = data;
        this.left = null;
        this.right = null;
    }
}
// module.exports = Nodo;
export default Nodo;