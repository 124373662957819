import React, { useState } from "react";
import { useSelector,useDispatch } from 'react-redux';
import { activeArbol, hideDetailArbol } from "../../actions/mapaActions";
import { useMapEvents } from 'react-leaflet/hooks'
import L, { MarkerCluster } from "leaflet";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";

import "./MarkerCluster.Default.css";
import "./MapaComponent.css";
// import { arboles } from "./arbolesPlantados";
// import { db } from "../../firebase/firebase-config";
import {usuarios as users} from './usuarios';
// import PopupMarker from "./PopupMarker";
import DetailArbol from "./DetailArbol";
import FiltroComponent from "./filtro/FiltroComponent";
import Navbar from "./Navbar";
const customIcon = new L.Icon({
  iconUrl: require("./location.svg").default,
  iconSize: new L.Point(40, 47),
});
const customIconStation = new L.Icon({
  iconUrl: require("./station.svg").default,
  iconSize: new L.Point(40, 47),
});
const estaciones =
[
  {
   "Nro": 1,
   "Departamento": "Cochabamba",
   "Estación": "Picode Loro -\nGPRS",
   "Provincia": "Quillacollo",
   "Municipio": "Vinto",
   "Latitud": -17.4053,
   "Longitud": -66.3206,
   "Altitud": 2548,
   "Tipo": "Hidrológica de 3er\nOrden",
   "Propietario": "Servicio Departamental\nde Cuencas",
   "Financiador": "Banco Mundial",
   "Inicio": 2017,
   "Fin": 2021,
   "Record_Años": 4,
   "NIVEL": "X",
   "PCPN": "X",
   "Distancia_Km": 35
  },
  {
   "Nro": 2,
   "Departamento": "Cochabamba",
   "Estación": "Puente Abra -\nGPRS",
   "Provincia": "Chapare",
   "Municipio": "Sacaba",
   "Latitud": -17.3964,
   "Longitud": -66.1125,
   "Altitud": 2636,
   "Tipo": "Hidrológica de 3er\nOrden",
   "Propietario": "Servicio Departamental\nde Cuencas",
   "Financiador": "Banco Mundial",
   "Inicio": 2017,
   "Fin": 2021,
   "Record_Años": 4,
   "NIVEL": "X",
   "PCPN": "X",
   "Distancia_Km": 14
  },
  {
   "Nro": 3,
   "Departamento": "Cochabamba",
   "Estación": "Puente Cajón -\nGPRS",
   "Provincia": "Cercado",
   "Municipio": "Cochabamba",
   "Latitud": -17.4142,
   "Longitud": -66.1828,
   "Altitud": 2564,
   "Tipo": "Hidrológica de 3er\nOrden",
   "Propietario": "Servicio Departamental\nde Cuencas",
   "Financiador": "Banco Mundial",
   "Inicio": 2017,
   "Fin": 2021,
   "Record_Años": 4,
   "NIVEL": "X",
   "PCPN": "X",
   "Distancia_Km": 8
  },
  {
   "Nro": 4,
   "Departamento": "Cochabamba",
   "Estación": "Puente Pojo-\nGPRS",
   "Provincia": "Carrasco",
   "Municipio": "Pojo",
   "Latitud": -18.09277,
   "Longitud": -64.698,
   "Altitud": 1448,
   "Tipo": "Hidrológica GPRS",
   "Fin": 2021
  },
  {
   "Nro": 5,
   "Departamento": "Cochabamba",
   "Estación": "Puente Siles -\nGPRS",
   "Provincia": "Cercado",
   "Municipio": "Cochabamba",
   "Latitud": -17.374,
   "Longitud": -66.131,
   "Altitud": 2616,
   "Tipo": "Hidrológica GPRS",
   "Fin": 2021
  },
  {
   "Nro": 6,
   "Departamento": "Cochabamba",
   "Estación": "Puente Mizque -\nGPRS",
   "Provincia": "Mizque",
   "Municipio": "Mizque",
   "Latitud": -17.92132,
   "Longitud": -65.2944,
   "Altitud": 2055,
   "Tipo": "Hidrológica de 3er\nOrden",
   "Propietario": "Servicio Departamental\nde Cuencas",
   "Financiador": "Banco Mundial",
   "Inicio": 2018,
   "Fin": 2021,
   "Record_Años": 3,
   "NIVEL": "X"
  },
  {
   "Nro": 7,
   "Departamento": "Cochabamba",
   "Estación": "Puente Omereque\n- GPRS",
   "Provincia": "Campero",
   "Municipio": "Omereque",
   "Latitud": -18.12896,
   "Longitud": -64.8926,
   "Altitud": 1594,
   "Tipo": "Hidrológica de 3er\nOrden",
   "Propietario": "Servicio Departamental\nde Cuencas",
   "Financiador": "Banco Mundial",
   "Inicio": 2018,
   "Fin": 2021,
   "Record_Años": 3,
   "NIVEL": "X"
  },
  {
   "Nro": 8,
   "Departamento": "Cochabamba",
   "Estación": "Totora Ohocha\nHidro - GPRS",
   "Provincia": "Tiraque",
   "Municipio": "Tiraque",
   "Latitud": -17.457,
   "Longitud": -65.627,
   "Altitud": 3763,
   "Tipo": "Hidrológica de 3er\nOrden",
   "Propietario": "VRHR - MMAyA",
   "Financiador": "Banco Mundial",
   "Inicio": 2018,
   "Fin": 2021,
   "Record_Años": 3,
   "NIVEL": "X",
   "PCPN": "X"
  },
  {
   "Nro": 9,
   "Departamento": "Cochabamba",
   "Estación": "Presa Pachaj\nOhocha - GPRS",
   "Provincia": "Tiraque",
   "Municipio": "Tiraque",
   "Latitud": -17.384,
   "Longitud": -65.655,
   "Altitud": 3995,
   "Tipo": "Hidrológica GPRS",
   "Fin": 2021
  },
  {
   "Nro": 10,
   "Departamento": "Cochabamba",
   "Estación": "Puente Déc ma -\nGPRS",
   "Provincia": "Chapare",
   "Municipio": "Sacaba",
   "Latitud": -17.394,
   "Longitud": -66.081,
   "Altitud": 2647,
   "Tipo": "Hidrológica GPRS",
   "Fin": 2021
  }
 ];
const createClusterCustomIcon = function (cluster: MarkerCluster) {
  return L.divIcon({
    html: `<span>${cluster.getChildCount()}</span>`,
    className: "custom-marker-cluster",
    iconSize: L.point(33, 33, true),
  });
};

const MapaComponent = () => {
  // const [usuarios, setUsuarios] = useState(users);
  const {arboles:arbolesPlantados,active,filtro,filtroAplied} = useSelector(state=>state.mapa);
  const dispatch = useDispatch();
  // const [arbolesPlantados, setArbolesPlantados] = useState(arboles);

  // const [arbolDetail, setArbolDetail] = useState(null);
  // const asArray = Object.entries(users);
 
  
  // console.log(asArray);
  // useEffect(() => {
    // getUsuariosFromDB();
    // getArbolesFromDB();
  // }, []);

  // const getUsuariosFromDB = async () => {
  //   const usuariosSnapshot = await db.collection("usuarios_public").get();
  //   const arrayUsuarios = [];
  //   usuariosSnapshot.forEach((element) => {
  //     const usuario = element.data();
  //     arrayUsuarios.push(usuario);
  //   });
  //   setUsuarios(arrayUsuarios);
  // };
  // const getArbolesFromDB = async () => {
  //   const arbolesSnapshot = await db.collection("arbolesPlantados").get();
  //   const arrayArboles = [];
  //   arbolesSnapshot.forEach((element) => {
  //     const arbol = element.data();
  //     arrayArboles.push(arbol);
  //   });
  //   setArbolesPlantados(arrayArboles);
  // };
  // console.log('desde Map');
  function MyComponent() {
    const map = useMapEvents({
      click: () => {
        // console.log('map clicked');
        if (active!==null) {
          dispatch(hideDetailArbol());
          document.querySelector('.leaflet-control-zoom-in').style.display='block';
          document.querySelector('.leaflet-control-zoom-out').style.display='block';
        }
      },
    
    })
    return null
  }
  return (

 <main >
      {/* <Navbar /> */}
      <DetailArbol />
      
      <FiltroComponent />
      <MapContainer
        center={[-17.3917, -66.1448]}
        zoom={13}
        scrollWheelZoom={true}

      >
         
        <MyComponent />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          
        />
           <MarkerClusterGroup chunkedLoading>
          {
            filtroAplied
            ?
            filtro.map((item) => (
              <Marker
                key={item.id}
                position={[item.latitud, item.longitud]}
                title={item.nombrePropio}
                icon={customIcon}
                eventHandlers={{
                  click: () => {
                    if(active?.id!==item?.id){
                      dispatch(activeArbol(item.id,{...item}))
                      document.querySelector('.leaflet-control-zoom-in').style.display='none';
                      document.querySelector('.leaflet-control-zoom-out').style.display='none';
                    }
                  },
                }}
              >
              {/* <PopupMarker arbol={item} usuarios={item.usuariosQueAdoptaron} arrayUsers={asArray} setArbolDetail={setArbolDetail} /> */}
                
              </Marker>
            ))
            :
            arbolesPlantados.map((item) => (
              <Marker
                key={item.id}
                position={[item.latitud, item.longitud]}
                title={item.nombrePropio}
                icon={customIcon}
                eventHandlers={{
                  click: () => {
                    if(active?.id!==item?.id){
                      dispatch(activeArbol(item.id,{...item}))
                      document.querySelector('.leaflet-control-zoom-in').style.display='none';
                      document.querySelector('.leaflet-control-zoom-out').style.display='none';
                    }
                  },
                }}
              >
              {/* <PopupMarker arbol={item} usuarios={item.usuariosQueAdoptaron} arrayUsers={asArray} setArbolDetail={setArbolDetail} /> */}
                
              </Marker>
            ))

          }
        </MarkerClusterGroup>
        {/* <MarkerClusterGroup chunkedLoading>
          {
           
            estaciones.map((item) => (
              <Marker
                key={item.Nro}
                position={[item.Latitud, item.Longitud]}
                title={item.Estación}
                icon={customIconStation}
                eventHandlers={{
                  click: () => {
                    if(active?.id!==item?.id){
                      dispatch(activeArbol(item.id,{...item}))
                      document.querySelector('.leaflet-control-zoom-in').style.display='none';
                      document.querySelector('.leaflet-control-zoom-out').style.display='none';
                    }
                  },
                }}
              >
                
              </Marker>
            ))

          }
        </MarkerClusterGroup> */}
        
      </MapContainer>
     
    </main>
   
   
  );
}

export default MapaComponent;
